import { Component, OnInit, Input } from '@angular/core';
import { IncidenceModel, TypeIncidence } from 'src/app/models/incidence.model';
import { ReturnService } from 'src/app/services/return.service';

@Component({
  selector: 'app-refund-money',
  templateUrl: './refund-money.component.html',
  styleUrls: ['./refund-money.component.scss']
})
export class RefundMoneyComponent implements OnInit {

  @Input() incidenceId: number;

  constructor(private returnService: ReturnService) { }

  ngOnInit() {

    const incidence = {} as IncidenceModel;
    incidence.incidenceId = this.incidenceId;
    incidence.type = TypeIncidence.REFUND;
    incidence.status = 0
    console.log('incidence save', incidence);
    
    this.returnService.save(incidence).subscribe(
      data => {
        console.log('exit', data)
      },
      error => {
        console.error(error);
      }
    );
  }

}
