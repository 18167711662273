import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ReturnService } from 'src/app/services/return.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  incidenceId: number;
  typePage: string;
  constructor(private route: ActivatedRoute, private router: Router, private returnService: ReturnService) { }

  ngOnInit() {

    this.route.queryParams.subscribe((params: Params) => {
      this.incidenceId = +params['incidenceId'];
      console.log(this.incidenceId);
      console.log(params['reason'])
      let error1;
      if (this.incidenceId != null && this.incidenceId != undefined) {
        this.returnService.findById(this.incidenceId).subscribe(
          data => {
            console.log('exit', data)
            this.router.navigate(['/previous']);
          },
          error => {
            error1 = error;
            if(error1.status == 404){
             this.typePage = params['reason'];
            } else {
             this.router.navigate(['/previous']);
            }
            console.error(error);
          }
        );
        
        console.log(this.typePage);
      } else {
        this.router.navigate(['/previous']);
      }
    });
  }


}
