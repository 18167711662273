<div class="container-fluid">
    <div class="row align-items-center vh-100">
        <div class="offset-3 col-6">
            <div class="logo text-center">
                <img class="img-logo" src="/assets/images/logo-pvea.png">
            </div>

            <div class="title">
                Gracias por tu confirmación
            </div>

            <div class="message">
                Si el pago lo realizaste en efectivo o tarjeta de débito, deberás<br>
                acercarte a la sección de Electro de la tienda donde gestionaste<br>
                tu solicitud, con tu documento de identidad vigente para que<br>
                procedan con el reembolso.<br>
                <br>
                Si el pago lo realizaste con tu tarjeta de crédito, coordinaremos<br>
                el extorno en un plazo máximo de 48 horas hábiles luego de<br>
                recibir tu respuesta y no será necesario que te acerques a la<br>
                tienda.<br>
            </div>
            <div class="row">
                <div class="offset-1 col-10 container-message pt-2 mt-5">
                    <div class="row">
                        <div class="col-1">
                            <img class="img-alert" src="/assets/images/alert.png">
                        </div>
                        <div class="col-11 message-alert pt-1">
                            <p>El plazo de atención del extorno puede tomar entre 15 a<br>
                                25 días útiles, el mismo depende de tu entidad financiera</p>
                        </div>
                    </div>
                </div>
            </div>
        <div>
    </div>
</div>