
export class IncidenceModel {
    incidenceId: number;
    type: TypeIncidence;
    status: number;
}

export enum TypeIncidence {
    CHANGE_SAME_PRODUCT = "CHANGE_SAME_PRODUCT",
    CHANGE_ANOTHER_PRODUCT = "CHANGE_ANOTHER_PRODUCT",
    REFUND = "REFUND",
}