<div class="container-fluid">
    <div class="row align-items-center vh-100">
        <div class="offset-3 col-6">
            <div class="logo text-center">
                <img class="img-logo" src="/assets/images/logo-pvea.png">
            </div>

            <div class="title">
                Gracias por tu confirmación
            </div>

            <div class="message">
                Recuerda que para hacer el cambio por otro producto deberás
                acercarte a la sección de Electro de la tienda donde gestionaste
                tu solicitud, con tu documento de identidad vigente. Se te
                entregará una nota de crédito por el monto del producto que
                deseas cambiar. Este cambio aplica para cualquier producto de
                la tienda.
            </div>
        <div>
    </div>
</div>