<div class="container-fluid">
    <div class="row align-items-center vh-100">
        <div class="offset-3 col-6">
            <div class="logo text-center">
                <img class="img-logo" src="/assets/images/logo-pvea.png">
            </div>

            <div class="title">
                Tu confirmación fue enviada<br>
                anteriormente.
            </div>
        </div>
    </div>
</div>
