import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IncidenceModel } from '../models/incidence.model';

@Injectable({
  providedIn: 'root'
})
export class ReturnService {

  urlBase: string = environment.API_RETURN_REQUEST;
  constructor(private http: HttpClient) { }

  save(incidence: IncidenceModel): Observable<IncidenceModel> {
    return this.http.post<IncidenceModel>(this.urlBase, incidence);
  }

  findById(incidenceId: number): Observable<IncidenceModel> {
    return this.http.get<IncidenceModel>(`${this.urlBase}/${incidenceId}`);
  }
}
