import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ExchangeSameProductComponent } from './pages/exchange-same-product/exchange-same-product.component';
import { ChangeAnotherProductComponent } from './pages/change-another-product/change-another-product.component';
import { RefundMoneyComponent } from './pages/refund-money/refund-money.component';
import { PreviousMessageComponent } from './pages/previous-message/previous-message.component';
import { HomeComponent } from './pages/home/home.component';

@NgModule({
  declarations: [
    AppComponent,
    ExchangeSameProductComponent,
    ChangeAnotherProductComponent,
    RefundMoneyComponent,
    PreviousMessageComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
