import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-previous-message',
  templateUrl: './previous-message.component.html',
  styleUrls: ['./previous-message.component.scss']
})
export class PreviousMessageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
