<div [ngSwitch]="typePage">
    <div *ngSwitchCase="'CHANGE_SAME_PRODUCT'">
        <app-exchange-same-product [incidenceId]="incidenceId">
        </app-exchange-same-product>
    </div>
    <div *ngSwitchCase="'CHANGE_ANOTHER_PRODUCT'">
        <app-change-another-product [incidenceId]="incidenceId">
        </app-change-another-product>
    </div>
    <div *ngSwitchCase="'REFUND'">
        <app-refund-money [incidenceId]="incidenceId">
        </app-refund-money>
    </div>
</div>