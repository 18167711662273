<div class="container-fluid">
    <div class="row align-items-center vh-100">
        <div class="offset-3 col-6">
            <div class="logo text-center">
                <img class="img-logo" src="/assets/images/logo-pvea.png">
            </div>

            <div class="title">
                Gracias por tu confirmación
            </div>

            <div class="message">
                Recuerda que para hacer el cambio por el mismo producto<br>
                deberás acercarte a la sección de Electro de la tienda donde<br>
            gestionaste tu solicitud, con tu documento de identidad vigente.<br>
            </div>
        <div>
    </div>
</div>
